import React, { useState, useEffect } from 'react';
import { useScorabilitySlugVerificationAndCustomToken, useMentalityAssessmentQuestions } from '../../hooks';
import _ from 'lodash';
import { AssessmentVersionsRef, TestAssessmentRef } from '../../utils/refs';
import { getCurrentUserId } from '../../utils';
import { getDoc, setDoc } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

function NetworkStatus() {
  /*
    All tests include:

    Ability to make network request to backend using http request (with ping) - could indicate issues with connectivity or our servers
    Ability to fetch document from Google Firestore - likely indicates issue with antivirus preventing network request due to Google Firestore request being classified as untrusted

  */

  const [isTesting, setIsTesting] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [testStatuses, setTestStatuses] = useState({
    authenticate: undefined,
    dbread: undefined,
    network: undefined,
    functions: undefined,
    dbwrite: undefined
  });
  console.log('getCurrentUserId()', getCurrentUserId());
  const { data: customToken, isLoading: customTokenLoading } = useScorabilitySlugVerificationAndCustomToken('test');

  useEffect(() => {
    if (!customTokenLoading) {
      canAuthenticateUser();
    }
    console.log('testStatuses', testStatuses);
  }, [customTokenLoading]);

  const canAuthenticateUser = async () => {
    // call useScorabilitySlugVerificationAndCustomToken
    if (customToken) {
      // sign in with custom token
      const auth = getAuth();

      signInWithCustomToken(auth, customToken)
        .then(async (userCredential) => {
          console.log('signed in with custom token', userCredential);
          setTestStatuses((prev) => ({
            ...prev,
            authenticate: true
          }));
          await canReadFromDatabase();
          await canWriteToDatabase();
        })
        .catch((error) => {
          console.log('Could not sign in with custom token', error);
          setTestStatuses((prev) => ({
            ...prev,
            authenticate: false
          }));
        });
    } else {
      setTestStatuses((prev) => ({
        ...prev,
        authenticate: false
      }));
    }
  };

  const canReadFromDatabase = async () => {
    const assessmentVersionId = '1.0';
    const assessmentQuestionsDoc = AssessmentVersionsRef(assessmentVersionId);

    try {
      const doc = await getDoc(assessmentQuestionsDoc);
      const data = doc.data();
      if (data) {
        setTestStatuses((prev) => ({
          ...prev,
          dbread: true
        }));
      } else {
        setTestStatuses((prev) => ({
          ...prev,
          dbread: false
        }));
      }
    } catch (error) {
      console.log('error', error);
      setTestStatuses((prev) => ({
        ...prev,
        dbread: false
      }));
    }
  };

  const canWriteToDatabase = async () => {
    // try to write to a test collection
    // if successful, set testStatuses.dbwrite to true
    // if unsuccessful, set testStatuses.dbwrite to false
    const assessmentTestRefDoc = TestAssessmentRef();

    try {
      await setDoc(assessmentTestRefDoc, {
        test: 'test'
      });

      setTestStatuses((prev) => ({
        ...prev,
        dbwrite: true
      }));
    } catch (error) {
      console.log('error', error);
      setTestStatuses((prev) => ({
        ...prev,
        dbwrite: false
      }));
    }
  };

  console.log('testStatuses', testStatuses);
  console.log('current user', getCurrentUserId());
  return (
    <>
      <h1>Network Status</h1>
      {
        <React.Fragment>
          <div>{`Authenticate User: ${testStatuses?.authenticate?.toString()}`}</div>
          <div>{`Assessment Loads: ${testStatuses?.dbread?.toString()}`}</div>
          <div>{`Assessment Can be Completed: ${'false'}`}</div>
          {/* <div className="test-frame">Network Ping: {network && network !== 'fail' ? getIcon('check') : network === 'fail' ? getIcon('check-fail') : getIcon('check-empty')}</div>
          <div className="test-frame">Network Functions: {functions && functions !== 'fail' ? getIcon('check') : functions === 'fail' ? getIcon('check-fail') : getIcon('check-empty')}</div>
          <div className="test-frame">Database Read: {dbread && dbread !== 'fail' ? getIcon('check') : dbread === 'fail' ? getIcon('check-fail') : getIcon('check-empty')}</div>
          <div className="test-frame">Database Write: {dbwrite && dbwrite !== 'fail' ? getIcon('check') : dbwrite === 'fail' ? getIcon('check-fail') : getIcon('check-empty')}</div> */}
        </React.Fragment>
      }
    </>
  );
}

export default NetworkStatus;
